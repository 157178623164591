<template>
  <div>
    <ul class="bg-gray-500 flex justify-center items-center p-4">
      <li>
        <router-link
          to="/vor/MembersUpload"
          :style="{
            color: $route.path === '/vor/MembersUpload' ? 'yellow' : '',
          }"
          >Mitgliederdaten laden</router-link
        >
      </li>
      <li>
        <router-link
          to="/vor/MemberRestrictions"
          :style="{
            color: $route.path === '/vor/MemberRestrictions' ? 'yellow' : '',
          }"
          >Mitgliederdaten Berechtigungen</router-link
        >
      </li>
    </ul>
    <router-view></router-view>
    <!-- Display submenu content -->
  </div>
</template>

<script>
export default {
  props: {
    msg: String,
  },

  methods: {},
  mounted() {
    this.$nextTick(() => {
      // Access DOM elements here
      const parentNode = document.getElementById("parent-node");
      // Check if the parent node exists before accessing its properties
      if (parentNode) {
        // Access properties or methods of the parent node
      }
    });
  },
};
</script>
<style scoped>
.list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.list-header {
  display: flex;
  background-color: #f0f0f0;
  padding: 5px;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
}

.list-item {
  display: flex;
  padding: 5px;
  border-bottom: 1px solid #ccc;
}

.list-header span,
.list-item span {
  flex: 1;
  text-align: left;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  position: relative; /* Make the position relative for proper positioning */
}

.modal-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
}
.close {
  cursor: pointer;
  font-size: 20px; /* Adjust as needed */
  margin-right: 10px; /* Add spacing between the close symbol and content */
}

.small-table {
  table-layout: auto;
  /* Additional styles for the table */
}
.custom-table {
  table-layout: auto;
  width: 80%; /* Set the table width as needed */
}
.filter-pane {
  display: flex; /* Use flexbox layout */
}

.filter-container {
  margin-bottom: 1rem; /* Add some space between filter containers */
}
</style>
