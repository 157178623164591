import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import MemberOne from '../views/MemberOne.vue'
import LogOff from '../views/LogOff.vue'
import LogOn from '../views/LogOn.vue'
import SignUp from '../views/SignUp.vue'
import ForgetOne from '../views/ForgetOne.vue'
import PasswordChange from '../views/PasswordChange.vue'
import RestrictionChange from '../views/RestrictionChange.vue'
import UpLoad from '../views/UpLoad.vue'
import TestOne from '../views/TestOne.vue'
import TestTwo from '../views/TestTwo.vue'
import MembersUpload from '../views/UpLoad.vue';
import MemberRestrictions from '@/components/MemberRestrictions.vue';
import ShowTables from '@/components/ShowTables.vue';

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView
  },
  {
    path: '/member',
    name: 'MemberOne',
    component: MemberOne,
    meta: { requiresAuth: true },
  },
  {
    path: '/logoff',
    name: 'LogOff',
    component: LogOff,
    meta: { requiresAuth: true },
  },
  {
    path: '/logon',
    name: 'LogOn',
    component: LogOn,
  },
    {
    path: '/forget',
    name: 'ForgetOne',
    component: ForgetOne,
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUp,
  },
  {
    path: '/password',
    name: 'PasswordChange',
    component: PasswordChange,
  },  
  {
    path: '/restriction',
    name: 'RestrictionChange',
    component: RestrictionChange,
  },   
  {
    path: '/upload',
    name: 'UpLoad',
    component: UpLoad,
  }, 
  {
    path: '/vor',
    name: 'TestOne',
    component: TestOne,
     children: [
            {
         path: 'MembersUpload',
              component: MembersUpload
            },
            {
              path: 'MemberRestrictions',
              component: MemberRestrictions
            }
          ]
  }, 
    {
    path: '/adm',
    name: 'TestTwo',
    component: TestTwo,
     children: [
            {
         path: 'MembersUpload',
              component: MembersUpload
            },
            {
              path: 'MemberRestrictions',
              component: MemberRestrictions
            },
            {
              path: 'ShowTables',
              component: ShowTables
            },            
          ]
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
