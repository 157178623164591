import { createStore } from 'vuex';
import session from './modules/session'; // Import your session module

export default createStore({
  state: {
    user: null,
    mail: null,
    token: null,
    ipdec: null,
    isok: null,
    isvorstand: null,
    isadmin: null,
  },
  getters: {
      getMail: (state) => state.mail,
      getToken: (state) => state.token,
      getIpdec: (state) => state.ipdec,
  },
  mutations: {

    setUser(state, user) {
      state.user = user
      state.mail = user.email
      state.token = user.token
      state.ipdec = user.ipdec
    },
    setIsOk(state, isok) {
      state.isok = isok
    },
    setIsVorstand(state, isvorstand) {
      state.isvorstand = isvorstand
    },
    setIsAdmin(state, isadmin) {
      state.isadmin = isadmin
    },    
    clearUser(state) {
      state.user = null;
      state.mail = null;
      state.token = null;
      state.ipdec = null;
      state.isok = null;
      state.isvorstand = null;
      state.isadmin = null;
    },
  },
  actions: {
  },
  modules: {
    session, // Include your session module here
    // Other modules if you have them
  },
});
