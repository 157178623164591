<template>
  <div class="app">
    <div v-if="$store.state.session.warning">
      <p>Session timeout in 3 minutes. Do you want to continue?</p>
      <button @click="continueSession">Continue</button>
      <button @click="cancelSession">Cancel</button>
    </div>
    <!-- Your application content here -->
    <Navigation />
    <router-view />
  </div>
</template>

<script>
import Navigation from "./components/Navigation.vue";

export default {
  name: "app",
  components: {
    Navigation,
  },
  data() {
    return {
      scrollNav: null,
      mobile: null,
      mobileNav: null,
      windowWidth: null,
    };
  },
  mounted() {
    // Add event listeners for user interactions to reset the timeout
    this.addEventListeners();
  },
  beforeUnmount() {
    // Remove event listeners when the component is unmounted to avoid memory leaks
    this.removeEventListeners();
  },
  methods: {
    addEventListeners() {
      // Add event listeners for keyboard and mouse interactions
      window.addEventListener("keydown", this.resetTimeout);
      window.addEventListener("mousemove", this.resetTimeout);
    },
    removeEventListeners() {
      // Remove the event listeners when the component is unmounted
      window.removeEventListener("keydown", this.resetTimeout);
      window.removeEventListener("mousemove", this.resetTimeout);
    },
    resetTimeout() {
      // Dispatch an action to reset the timeout when the user interacts
      this.$store.dispatch("session/resetTimeout");
    },
    continueSession() {
      // Dispatch an action or perform any desired action when the user chooses to continue the session.
      this.$store.dispatch("session/resetTimeout");
    },
    cancelSession() {
      // Dispatch an action or perform any desired action when the user chooses to cancel the session.
      this.$store.dispatch("session/resetTimeout");
    },
  },
};
</script>


