<!-- forgetOneComponent.vue -->
<template>
  <div class="flex min-h-full flex-col justify-left px-6 py-12 lg:px-8">
    <div class="mt-10 sm:w-full sm:max-w-sm">
      <form @submit.prevent="login" class="space-y-6" action="#" method="POST">
        <div>
          <label
            for="email"
            class="block text-sm font-medium leading-6 text-gray-900"
            >Email</label
          >
          <div class="mt-2">
            <input
              id="email"
              v-model="email"
              type="email"
              autocomplete="email"
              required
              class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
          <div>
            <button
              type="submit"
              class="mt-2 flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Senden
            </button>
            <p class="text-red-400" v-if="error">{{ error }}</p>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { fetchData } from "@/services/fetchData";

export default {
  data() {
    return {
      email: "",
      error: "",
    };
  },
  methods: {
    async login() {
      const url = "forget_vue.php";
      const requestBody = {
        email: this.email,
        forgetpassword: true,
      };

      try {
        const result = await fetchData(url, requestBody);
        //console.log("Response from server:", result);
        //const status = result.status || (result.data && result.data.status);
        //console.log(status);

        const userData = {
          email: null,
          token: null,
          ipdec: null,
        };
        if (result.status === 200 || result.status === 201) {
          (userData.email = result.data.email),
            (userData.token = result.data.idToken),
            (userData.ipdec = result.data.ipdec),
            //console.log(userData);
            this.$store.commit("setUser", userData);
        }

        if (result.status === 201) {
          //console.log("Confirmation screen should be shown");
          //console.log(userData);
          // this.$store.commit("setUser", userData);
          this.$emit("login-success", userData);
        } else if (result.status === 200) {
          //console.log("Successful login!");
          // Set your store items here

          // Navigate to the home page
          this.$router.push({ name: "HomeView" });
        } else if (result.status >= 400 && result.status < 500) {
          //console.log("Client error:", result.status);
          //console.log(result);
          //console.log(result.data);
          //console.log(result.data.error.message);
          if (result.data.error) {
            if (result.data.error.message === "IPW") {
              this.error = "Passwort ungültig";
            } else {
              this.error = "ungültige Eingabe";
            }
          }
        } else if (result.status >= 500 && result.status < 600) {
          //console.log("Server error:", result.status);
        } else {
          //console.error("Unexpected response status:", result.status);
        }
      } catch (error) {
        //console.error("An error occurred:", error);
      }
    },
  },
};
</script>

<style scoped>
/* Add scoped styles as needed */
</style>
