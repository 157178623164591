<!-- PasswordChangeComponent.vue  -->
<template>
  <div class="flex min-h-full flex-col justify-left px-6 py-12 lg:px-8">
    <div class="mt-10 sm:w-full sm:max-w-sm">
      <form @submit.prevent="login">
        <table class="table-auto border-spacing-x-0">
          <tr>
            <td>
              <div class="flex items-center justify-between">
                <label
                  for="password"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Neues Passwort</label
                >
              </div>
            </td>
            <td>
              <div class="mt-2">
                <input
                  id="password"
                  v-model="password"
                  type="password"
                  required
                  autocomplete="current-password"
                  minlength="4"
                  maxlength="30"
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div class="flex items-center justify-between">
                <label
                  for="password"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Neues Passwort bestätigen</label
                >
              </div>
            </td>
            <td>
              <div class="mt-2">
                <input
                  id="password"
                  v-model="passwordc"
                  type="password"
                  required
                  autocomplete="current-password"
                  minlength="4"
                  maxlength="30"
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </td>
          </tr>

          <tr>
            <p v-if="!passwordsMatch" style="color: red">
              Die Passwörter sind nicht identisch
            </p>
          </tr>
          <tr>
            <td></td>
            <td>
              <div class="flex items-center justify-between mt-2">
                <button
                  type="submit"
                  class="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Senden
                </button>
              </div>
            </td>
          </tr>
          <p class="text-red-400" v-if="error">{{ error }}</p>
        </table>
      </form>
    </div>
  </div>
</template>
<script>
import { fetchData } from "@/services/fetchData";

export default {
  data() {
    return {
      error: "",
      password: "",
      passwordc: "",
    };
  },
  methods: {
    async login() {
      const url = "password_change_vue.php";
      const requestBody = {
        email: this.$store.getters.getMail,
        password: this.password,
      };

      try {
        const result = await fetchData(url, requestBody);
        //console.log("Response from server:", result);
        //const status = result.status || (result.data && result.data.status);
        //console.log(status);

        if (result.status === 201) {
          //console.log("Password screen should be shown");
          this.$emit("login-success");
        } else if (result.status === 200) {
          //console.log("Successful login!");
          // Set your store items here
          // Navigate to the home page
          this.$router.push({ name: "HomeView" });
        } else if (result.status >= 400 && result.status < 500) {
          //console.log("Client error:", result.status);
          //console.log(result);
          //console.log(result.data);
          //console.log(result.data.error.message);
          if (result.data.error) {
            if (result.data.error.message === "IPW") {
              this.error = "Passwort ungültig";
            } else {
              this.error = "ungültige Eingabe";
            }
          }
        } else if (result.status >= 500 && result.status < 600) {
          //console.log("Server error:", result.status);
        } else {
          //console.error("Unexpected response status:", result.status);
        }
      } catch (error) {
        //console.error("An error occurred:", error);
      }
    },
  },
  computed: {
    passwordsMatch() {
      return this.password === this.passwordc;
    },
  },
};
</script>

<style scoped>
/* Add scoped styles as needed */
</style>
