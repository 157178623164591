<!-- RestrictionChangeComponent.vue  -->
<template>
  <div class="flex min-h-full flex-col justify-left px-6 py-12 lg:px-8">
    <div class="mt-10 sm:w-full sm:max-w-sm">
      <form @submit.prevent="login">
        <table class="table-auto border-spacing-x-0">
          <tr>
            <div class="flex items-center justify-between">
              <br />
              Wie sollen deine Daten in der Mitgliederliste angezeigt werden?
            </div>
          </tr>
          <tr>
            <div class="flex items-center justify-between">
              <select id="select" v-model="restriction">
                <option value="">Wähle deine Variante</option>
                <option value="9">
                  Alles anzeigen (Telefon, Mail, Adresse)
                </option>
                <option value="1">Mail anzeigen</option>
                <option value="2">Telefon anzeigen</option>
                <option value="3">Telefon und Mail anzeigen</option>
                <option value="4">Adressdaten anzeigen</option>
                <option value="0">Keine Daten anzeigen</option>
              </select>
            </div>
          </tr>
          <tr>
            <br />
            <div class="flex items-center justify-between">
              <p v-if="!restriction" style="color: red">
                Bitte eine Variante auswählen
              </p>
            </div>
          </tr>
          <tr>
            <br />

            <div class="flex items-center justify-between">
              <button
                type="submit"
                class="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Senden
              </button>
            </div>
          </tr>
        </table>
        <p class="text-red-400" v-if="error">{{ error }}</p>
      </form>
      <p></p>
    </div>
  </div>
</template>

<script>
import { fetchData } from "@/services/fetchData";

export default {
  data() {
    return {
      email: "",
      password: "",
      restriction: "",
      passwordc: "",
      ok: true,
      error: "",
    };
  },
  methods: {
    async login() {
      const url = "restriction_change_vue.php";
      const requestBody = {
        email: this.$store.getters.getMail,
        restriction: this.restriction,
      };

      try {
        const result = await fetchData(url, requestBody);
        //console.log("Response from server:", result);
        //const status = result.status || (result.data && result.data.status);
        //console.log(status);

        const userData = {
          email: null,
          token: null,
          ipdec: null,
        };

        if (result.status === 201) {
          //console.log("Confirmation screen should be shown");
          this.$emit("login-success", userData);
        } else if (result.status === 200) {
          //console.log("Successful confirmation");
          this.$router.push({ name: "HomeView" });
        } else if (result.status >= 400 && result.status < 500) {
          //console.log("Client error:", result.status);
          //console.log(result);
          //console.log(result.data);
          //console.log(result.data.error.message);
          if (result.data.error) {
            if (result.data.error.message === "NAU") {
              // already created
              this.error = "Es besteht bereits ein Konto";
            } else {
              this.error = "ungültige Eingabe";
            }
          }
        } else if (result.status >= 500 && result.status < 600) {
          //console.log("Server error:", result.status);
        } else {
          //console.error("Unexpected response status:", result.status);
        }
      } catch (error) {
        //console.error("An error occurred:", error);
      }
    },
  },
  computed: {
    passwordsMatch() {
      return this.password === this.passwordc;
    },
  },
};
</script>
