<template>
  <div class="min-h-full">
    <Disclosure as="nav" class="bg-gray-800" v-slot="{ open }">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="flex h-16 items-center justify-between">
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <img
                class="w-10 md:w-8 lg:w-16"
                src="@/assets/TCE_Logo.svg"
                alt="Logo"
              />
            </div>
            <div class="hidden md:block">
              <div class="ml-10 flex items-baseline space-x-4">
                <ul class="flex items-baseline space-x-4">
                  <li class="mr-3">
                    <router-link
                      :to="{ name: 'HomeView' }"
                      :class="{
                        'text-yellow-500': $route.name === 'HomeView',
                        'text-gray-300 hover:bg-gray-700 hover:text-white':
                          $route.name !== 'HomeView',
                      }"
                      class="rounded-md px-3 py-2 text-sm font-medium"
                      >Home</router-link
                    >
                  </li>
                  <li v-if="isAuthenticated" class="mr-3">
                    <router-link
                      :to="{ name: 'LogOff' }"
                      :class="{
                        'text-yellow-500': $route.name === 'LogOff',
                        'text-gray-300 hover:bg-gray-700 hover:text-white':
                          $route.name !== 'LogOff',
                      }"
                      class="rounded-md px-3 py-2 text-sm font-medium"
                      >Abmelden</router-link
                    >
                  </li>
                  <li v-if="!isAuthenticated" class="mr-3">
                    <router-link
                      :to="{ name: 'LogOn' }"
                      :class="{
                        'text-yellow-500': $route.name === 'LogOn',
                        'text-gray-300 hover:bg-gray-700 hover:text-white':
                          $route.name !== 'LogOn',
                      }"
                      class="rounded-md px-3 py-2 text-sm font-medium"
                      >Anmelden</router-link
                    >
                  </li>
                  <li v-if="isAuthenticated" class="mr-3">
                    <router-link
                      :to="{ name: 'MemberOne' }"
                      :class="{
                        'text-yellow-500': $route.name === 'MemberOne',
                        'text-gray-300 hover:bg-gray-700 hover:text-white':
                          $route.name !== 'MemberOne',
                      }"
                      class="rounded-md px-3 py-2 text-sm font-medium"
                      >Mitgliederliste</router-link
                    >
                  </li>
                  <li v-if="isAuthenticated" class="mr-3">
                    <router-link
                      :to="{ name: 'PasswordChange' }"
                      :class="{
                        'text-yellow-500': $route.name === 'PasswordChange',
                        'text-gray-300 hover:bg-gray-700 hover:text-white':
                          $route.name !== 'PasswordChange',
                      }"
                      class="rounded-md px-3 py-2 text-sm font-medium"
                      >Passwort ändern</router-link
                    >
                  </li>
                  <li v-if="isAuthenticated" class="mr-3">
                    <router-link
                      :to="{ name: 'RestrictionChange' }"
                      :class="{
                        'text-yellow-500': $route.name === 'RestrictionChange',
                        'text-gray-300 hover:bg-gray-700 hover:text-white':
                          $route.name !== 'RestrictionChange',
                      }"
                      class="rounded-md px-3 py-2 text-sm font-medium"
                      >Berechtigung ändern</router-link
                    >
                  </li>
                  <li v-if="!isAuthenticated" class="mr-3">
                    <router-link
                      :to="{ name: 'ForgetOne' }"
                      :class="{
                        'text-yellow-500': $route.name === 'ForgetOne',
                        'text-gray-300 hover:bg-gray-700 hover:text-white':
                          $route.name !== 'ForgetOne',
                      }"
                      class="rounded-md px-3 py-2 text-sm font-medium"
                      >Passwort vergessen</router-link
                    >
                  </li>
                  <li v-if="!isAuthenticated" class="mr-3">
                    <router-link
                      :to="{ name: 'SignUp' }"
                      :class="{
                        'text-yellow-500': $route.name === 'SignUp',
                        'text-gray-300 hover:bg-gray-700 hover:text-white':
                          $route.name !== 'SignUp',
                      }"
                      class="rounded-md px-3 py-2 text-sm font-medium"
                      >Registrieren</router-link
                    >
                  </li>
                  <li v-if="isVorstand || isAdmin" class="mr-3">
                    <router-link
                      :to="{ name: 'TestOne' }"
                      :class="{
                        'text-yellow-500': $route.name === 'TestOne',
                        'text-gray-300 hover:bg-gray-700 hover:text-white':
                          $route.name !== 'TestOne',
                      }"
                      class="rounded-md px-3 py-2 text-sm font-medium"
                      >Administration</router-link
                    >
                  </li>
                  <li v-if="isAdmin" class="mr-3">
                    <router-link
                      :to="{ name: 'TestTwo' }"
                      :class="{
                        'text-yellow-500': $route.name === 'TestTwo',
                        'text-gray-300 hover:bg-gray-700 hover:text-white':
                          $route.name !== 'TestTwo',
                      }"
                      class="rounded-md px-3 py-2 text-sm font-medium"
                      >Admin(Admin)</router-link
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="hidden md:block"></div>
          <div class="-mr-2 flex md:hidden">
            <DisclosureButton
              class="relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
            >
              <span class="absolute -inset-0.5" />
              <span class="sr-only">Open main menu</span>
              <Bars3Icon
                v-if="!open"
                class="block h-6 w-6"
                aria-hidden="true"
              />
              <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
            </DisclosureButton>
          </div>
        </div>
      </div>

      <DisclosurePanel class="md:hidden">
        <div class="space-y-1 px-2 pb-3 pt-2 sm:px-3">
          <ul class="flex flex-col space-y-1">
            <li class="mr-3">
              <router-link
                :to="{ name: 'HomeView' }"
                :class="{
                  'text-yellow-500': $route.name === 'HomeView',
                  'text-gray-300 hover:bg-gray-700 hover:text-white':
                    $route.name !== 'HomeView',
                }"
                class="block rounded-md px-3 py-2 text-base font-medium"
                >Home</router-link
              >
            </li>
            <li v-if="isAuthenticated" class="mr-3">
              <router-link
                :to="{ name: 'LogOff' }"
                :class="{
                  'text-yellow-500': $route.name === 'LogOff',
                  'text-gray-300 hover:bg-gray-700 hover:text-white':
                    $route.name !== 'LogOff',
                }"
                class="block rounded-md px-3 py-2 text-base font-medium"
                >Abmelden</router-link
              >
            </li>
            <li v-if="!isAuthenticated" class="mr-3">
              <router-link
                :to="{ name: 'LogOn' }"
                :class="{
                  'text-yellow-500': $route.name === 'LogOn',
                  'text-gray-300 hover:bg-gray-700 hover:text-white':
                    $route.name !== 'LogOn',
                }"
                class="block rounded-md px-3 py-2 text-base font-medium"
                >Anmelden</router-link
              >
            </li>
            <li v-if="isAuthenticated" class="mr-3">
              <router-link
                :to="{ name: 'MemberOne' }"
                :class="{
                  'text-yellow-500': $route.name === 'MemberOne',
                  'text-gray-300 hover:bg-gray-700 hover:text-white':
                    $route.name !== 'MemberOne',
                }"
                class="block rounded-md px-3 py-2 text-base font-medium"
                >Mitgliederliste</router-link
              >
            </li>
            <li v-if="isAuthenticated" class="mr-3">
              <router-link
                :to="{ name: 'PasswordChange' }"
                :class="{
                  'text-yellow-500': $route.name === 'PasswordChange',
                  'text-gray-300 hover:bg-gray-700 hover:text-white':
                    $route.name !== 'PasswordChange',
                }"
                class="block rounded-md px-3 py-2 text-base font-medium"
                >Passwort ändern</router-link
              >
            </li>
            <li v-if="isAuthenticated" class="mr-3">
              <router-link
                :to="{ name: 'RestrictionChange' }"
                :class="{
                  'text-yellow-500': $route.name === 'RestrictionChange',
                  'text-gray-300 hover:bg-gray-700 hover:text-white':
                    $route.name !== 'RestrictionChange',
                }"
                class="block rounded-md px-3 py-2 text-base font-medium"
                >Berechtigung ändern</router-link
              >
            </li>
            <li v-if="!isAuthenticated" class="mr-3">
              <router-link
                :to="{ name: 'ForgetOne' }"
                :class="{
                  'text-yellow-500': $route.name === 'ForgetOne',
                  'text-gray-300 hover:bg-gray-700 hover:text-white':
                    $route.name !== 'ForgetOne',
                }"
                class="block rounded-md px-3 py-2 text-base font-medium"
                >Passwort vergessen</router-link
              >
            </li>
            <li v-if="!isAuthenticated" class="mr-3">
              <router-link
                :to="{ name: 'SignUp' }"
                :class="{
                  'text-yellow-500': $route.name === 'SignUp',
                  'text-gray-300 hover:bg-gray-700 hover:text-white':
                    $route.name !== 'SignUp',
                }"
                class="block rounded-md px-3 py-2 text-base font-medium"
                >Registrieren</router-link
              >
            </li>
            <li v-if="isVorstand || isAdmin" class="mr-3">
              <router-link
                :to="{ name: 'TestOne' }"
                :class="{
                  'text-yellow-500': $route.name === 'TestOne',
                  'text-gray-300 hover:bg-gray-700 hover:text-white':
                    $route.name !== 'TestOne',
                }"
                class="block rounded-md px-3 py-2 text-base font-medium"
                >Administration</router-link
              >
            </li>
            <li v-if="isAdmin" class="mr-3">
              <router-link
                :to="{ name: 'TestTwo' }"
                :class="{
                  'text-yellow-500': $route.name === 'TestTwo',
                  'text-gray-300 hover:bg-gray-700 hover:text-white':
                    $route.name !== 'TestTwo',
                }"
                class="block rounded-md px-3 py-2 text-base font-medium"
                >Admin(Admin)</router-link
              >
            </li>
          </ul>
        </div>
      </DisclosurePanel>
    </Disclosure>

    <main>
      <div class="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8"></div>
    </main>
  </div>
</template>

<script>
export default {
  name: "NavigationComponent",
  data() {
    return {
      scrolledNav: false,
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    isAuthenticated() {
      console.log("isAuthenticated:", this.$store.state.isok !== null);
      return this.$store.state.isok !== null;
    },
    isAdmin() {
      console.log("isAdmin:", !!this.$store.state.isadmin);
      return !!this.$store.state.isadmin;
    },
    isVorstand() {
      console.log("isVorstand:", !!this.$store.state.isvorstand);
      return !!this.$store.state.isvorstand;
    },
  },
  created() {
    window.addEventListener("resize", this.checkScreen);
    window.addEventListener("scroll", this.updateScroll);
  },
  unmounted() {
    window.removeEventListener("resize", this.checkScreen);
    window.removeEventListener("scroll", this.updateScroll);
  },
  methods: {
    updateScroll() {
      this.scrolledNav = window.scrollY > 50;
    },
    checkScreen() {
      this.windowWidth = window.innerWidth;
    },
    logoff() {
      this.$store.commit("clearUser");
      this.$router.push({ name: "HomeView" });
    },
  },
};
</script>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { Bars3Icon, XMarkIcon } from "@heroicons/vue/24/outline";
</script>
