
<script>
export default {
  mounted() {
    this.logoff();
  },
  methods: {
    logoff() {
      // Clear the user credentials in your Vuex store
      this.$store.commit("clearUser");

      // Redirect to a login page or another desired route
      this.$router.push({ name: "HomeView" }); // Replace '/auth' with your login page route

      // You can also perform other actions such as showing a success message
      // or updating the UI to reflect that the user is logged off
    },
  },
};
</script>
