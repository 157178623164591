<!-- UpdateLogon.vue  -->
<template>
  <div v-if="!continuex">
    <h1>{{ msg }}</h1>
    <div class="flex min-h-full flex-col justify-left px-6 py-12 lg:px-8">
      <div class="mt-10 sm:w-full sm:max-w-sm">
        <form
          @submit.prevent="login"
          class="space-y-6"
          action="#"
          method="POST"
        >
          <div>
            <label
              for="email"
              class="block text-sm font-medium leading-6 text-gray-900"
              >Email</label
            >
            <div class="mt-2">
              <input
                id="email"
                v-model="email"
                type="email"
                autocomplete="email"
                required
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <div class="flex items-center justify-between">
              <label
                for="password"
                class="block text-sm font-medium leading-6 text-gray-900"
                >Passwort</label
              >
            </div>
            <div class="mt-2">
              <input
                id="password"
                v-model="password"
                type="password"
                autocomplete="current-password"
                required
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              class="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Senden
            </button>
            <p class="text-red-400" v-if="error">{{ error }}</p>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div v-if="continuex">
    <UploadCSV :msg="msg" />
  </div>
</template>
<script>
import { fetchData } from "@/services/fetchData";
import UploadCSV from "@/components/UploadCSV.vue";
export default {
  props: {
    msg: String,
  },
  data() {
    return {
      email: "",
      password: "",
      error: "",
      continuex: false,
    };
  },
  components: {
    UploadCSV,
  },
  methods: {
    async login() {
      const url = "LogonDBUpdate.php";
      const requestBody = {
        email: this.email,
        password: this.password,
      };

      try {
        const result = await fetchData(url, requestBody);
        //console.log("Response from server:", result);
        //const status = result.status || (result.data && result.data.status);
        //console.log(status);

        const userData = {
          email: null,
        };
        if (result.status === 200) {
          this.continuex = true;
          userData.email = result.data.email;
          //console.log(userData);
          this.$store.commit("setUser", userData);
        } else if (result.status >= 400 && result.status < 500) {
          //console.log("Logon error:", result.status);
          if (result.status == 401) {
            this.error = "Email oder Passwort falsch";
          } else {
            this.error = "Du bist nicht berechtigt, Daten zu laden!!";
          }
        } else if (result.status >= 500 && result.status < 600) {
          //console.log("Server error:", result.status);
        } else {
          //console.error("Unexpected response status:", result.status);
        }
      } catch (error) {
        //console.error("An error occurred:", error);
      }
    },
  },
};
</script>

<style scoped>
/* Add scoped styles as needed */
</style>

