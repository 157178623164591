// createApp(App).use(store).use(router).mount('#app')
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'tailwindcss/tailwind.css';
import { createMetaManager } from 'vue-meta'; // Correct import for Vue 3




const app = createApp(App);

// Timeout duration (20 minutes)
const authTimeout = 20 * 60 * 1000;


// Function to log the user out and redirect to the logon page
const logOutAndRedirect = () => {
 
  // Clear the user credentials in your Vuex store
  store.commit('clearUser');
  // Redirect to the logon page
  router.replace({ name: "HomeView" }); // Replace with your logon page route
};

// Initialize the user activity timeout
let timeoutId;

const resetUserActivityTimeout = () => {
  clearTimeout(timeoutId); // Clear the previous timeout
  timeoutId = setTimeout(logOutAndRedirect, authTimeout);
};

// Set up global event listeners to reset the user activity timeout
window.addEventListener('mousemove', resetUserActivityTimeout);
window.addEventListener('keydown', resetUserActivityTimeout);

// Create the app and use Vue Router and the store
app.use(store); // Use the Vuex store
app.use(router);

app.use(createMetaManager()); // Use VueMeta manager



app.mount('#app');   
