<!-- SignUpComponent.vue  -->
<template>
  <div>
    <form @submit.prevent="login">
      <table class="table-auto border-spacing-x-0">
        <tr>
          <td>
            <div class="flex items-center justify-between">
              <label
                for="email"
                class="block text-sm font-medium leading-6 text-gray-900"
                >Email</label
              >
            </div>
          </td>
          <td>
            <div class="mt-2">
              <input
                id="email"
                v-model="email"
                type="email"
                autocomplete="email"
                required
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </td>
        </tr>

        <tr>
          <td>
            <div class="flex items-center justify-between">
              <label
                for="password"
                class="block text-sm font-medium leading-6 text-gray-900"
                >Passwort</label
              >
            </div>
          </td>
          <td>
            <div class="mt-2">
              <input
                id="password"
                v-model="password"
                type="password"
                required
                autocomplete="current-password"
                minlength="4"
                maxlength="30"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </td>
        </tr>

        <tr>
          <td>
            <div class="flex items-center justify-between">
              <label
                for="password"
                class="block text-sm font-medium leading-6 text-gray-900"
                >Passwort bestätigen</label
              >
            </div>
          </td>
          <td>
            <div class="mt-2">
              <input
                id="password"
                v-model="passwordc"
                type="password"
                required
                autocomplete="current-password"
                minlength="4"
                maxlength="30"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </td>
        </tr>

        <tr>
          <p v-if="!passwordsMatch" class="text-red">
            Die Passwörter sind nicht identisch
          </p>
        </tr>

        <tr>
          <div class="flex items-center justify-between">
            <td>
              <label for="select"
                >Wie sollen deine Daten in der <br />Mitgliederliste angezeigt
                werden?</label
              >
            </td>
          </div>

          <td>
            <select id="select" v-model="restriction">
              <option value="">Wähle deine Variante</option>
              <option value="9">Alles anzeigen (Telefon, Mail, Adresse)</option>
              <option value="1">Mail anzeigen</option>
              <option value="2">Telefon anzeigen</option>
              <option value="3">Telefon und Mail anzeigen</option>
              <option value="4">Adressdaten anzeigen</option>
              <option value="0">Keine Daten anzeigen</option>
            </select>
          </td>
        </tr>
        <tr>
          <td></td>
          <td>
            <p v-if="!restriction" class="text-red">
              Bitte eine Variante auswählen
            </p>
          </td>
        </tr>

        <tr>
          <td></td>
          <td>
            <div class="flex items-center justify-between">
              <button
                type="submit"
                class="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Senden
              </button>
            </div>
          </td>
        </tr>
      </table>

      <p class="text-red-400" v-if="error">{{ error }}</p>
    </form>
    <p></p>
  </div>
</template>

<script>
import { fetchData } from "@/services/fetchData";

export default {
  data() {
    return {
      email: "",
      password: "",
      restriction: "",
      passwordc: "",
      ok: true,
      error: "",
    };
  },
  methods: {
    async login() {
      const url = "signup_vue.php";
      const requestBody = {
        email: this.email,
        password: this.password,
        restriction: this.restriction,
      };

      try {
        const result = await fetchData(url, requestBody);
        //console.log("Response from server:", result);
        //const status = result.status || (result.data && result.data.status);
        //console.log(status);

        const userData = {
          email: null,
          token: null,
          ipdec: null,
        };
        if (result.status === 200 || result.status === 201) {
          (userData.email = result.data.email),
            (userData.token = result.data.idToken),
            (userData.ipdec = result.data.ipdec),
            //console.log(userData);
            this.$store.commit("setUser", userData);
        }

        if (result.status === 201) {
          //console.log("Confirmation screen should be shown");
          //console.log(userData);
          this.$store.commit("setUser", userData);
          this.$emit("login-success", userData);
        } else if (result.status === 200) {
          //console.log("Successful SignUp!");
          // Set your store items here

          // Navigate to the home page
          this.$router.push({ name: "HomeView" });
        } else if (result.status >= 400 && result.status < 500) {
          //console.log("Client error:", result.status);
          //console.log(result);
          //console.log(result.data);
          //console.log(result.data.error.message);
          if (result.data.error) {
            if (result.data.error.message === "NAU") {
              // already created
              this.error = "Es besteht bereits ein Konto";
            } else {
              this.error = "ungültige Eingabe";
            }
          }
        } else if (result.status >= 500 && result.status < 600) {
          //console.log("Server error:", result.status);
        } else {
          //console.error("Unexpected response status:", result.status);
        }
      } catch (error) {
        //console.error("An error occurred:", error);
        // if (error instanceof BadRequestError) {
        //   this.error = error.message;
        //   //console.log("Bad Request Error data:", error.data);
        // } else {
        //   //console.error("An unexpected error occurred:", error);
        // }
      }
    },
  },
  computed: {
    passwordsMatch() {
      return this.password === this.passwordc;
    },
  },
};
</script>
