<template>
  <div v-if="showModal" class="modal">
    <div class="modal-content content-center">
      <!-- Close button -->
      <span class="close" @click="closeModal">&times;</span>

      <p>{{ modalContent }}</p>
      <p></p>
      <div v-if="showConfirmation">
        <div class="flex gap-3 max-w-sm">
          <button
            class="bg-green-600 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l"
            @click="confirmAction"
          >
            Yes
          </button>

          <button
            class="bg-red-600 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l"
            @click="cancelAction"
          >
            No
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="mt-10">
    <h1 class="text-3xl">{{ msg }}</h1>
    <div v-if="loading" class="spinner">
      <!-- Your spinner component or loading animation here -->
      <!-- For example, using a simple spinner provided by FontAwesome -->
      <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
    </div>
    <!-- Tables filter -->
    <div class="filter-container">
      <!-- <label for="anredeFilter">Anrede:</label> -->
      <p class="text-2xl">Tables</p>
      <select
        id="tablesFilter"
        v-model="selectedTables"
        size="5"
        @change="filteredTables"
      >
        <option
          v-for="(value, index) in uniqueTablesValues"
          :key="index"
          :value="value"
        >
          {{ value }}
        </option>
      </select>
    </div>
  </div>
  <div v-show="showSelect" class="mt-10">
    <div v-if="loading" class="spinner">
      <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
    </div>
    <input v-model="searchQuery" placeholder="Search..." @input="filterItems" />

    <!-- Dropdown menu to select number of lines per page -->
    <label for="pageSize">Zeilen pro Seite:</label>
    <select v-model="pageSize" @change="changePageSize">
      <option value="10">10</option>
      <option value="20">20</option>
      <option value="50">50</option>
      <option value="100">100</option>
    </select>

    <table
      rules="rows"
      cellspacing="5"
      cellpadding="5"
      class="custom-table overflow-x-auto"
    >
      <thead>
        <tr class="odd:bg-white even:bg-slate-200">
          <!-- Add a column for CRUD operations -->
          <th class="px-4 py-2">Actions</th>
          <th
            v-for="(fieldName, index) in fieldNames"
            :key="index"
            class="px-4 py-2"
          >
            <div
              @click="toggleSort(fieldName)"
              class="flex items-center justify-between cursor-pointer"
            >
              <span>{{ fieldName }}</span>
              <span
                v-if="sortField === fieldName"
                :class="{ 'transform rotate-180': sortOrder === 'desc' }"
                class="ml-1"
                >▲</span
              >
              <span
                v-if="sortField === fieldName && sortOrder === 'asc'"
                class="ml-1"
                >▼</span
              >
            </div>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="(item, rowIndex) in displayedData"
          :key="rowIndex"
          :class="{
            'bg-gray-100': rowIndex % 2 === 0,
            'bg-gray-200': rowIndex % 2 !== 0,
          }"
        >
          <!-- Add dropdown menu for CRUD operations -->
          <td class="text-base">
            <select v-model="selectedAction[rowIndex]">
              <option value="modify">Modify</option>
              <option value="delete">Delete</option>
              <option value="newcopy">Copy New</option>
              <option value="new">New</option>
            </select>

            <button
              class="bg-green-600 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l"
              @click="performAction(selectedAction[rowIndex], item)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                class="w-4 h-4"
              >
                <path
                  fill-rule="evenodd"
                  d="M3.5 2A1.5 1.5 0 0 0 2 3.5v9A1.5 1.5 0 0 0 3.5 14h9a1.5 1.5 0 0 0 1.5-1.5v-7A1.5 1.5 0 0 0 12.5 4H9.621a1.5 1.5 0 0 1-1.06-.44L7.439 2.44A1.5 1.5 0 0 0 6.38 2H3.5ZM8 6a.75.75 0 0 1 .75.75v1.5h1.5a.75.75 0 0 1 0 1.5h-1.5v1.5a.75.75 0 0 1-1.5 0v-1.5h-1.5a.75.75 0 0 1 0-1.5h1.5v-1.5A.75.75 0 0 1 8 6Z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </td>
          <td v-for="(value, fieldIndex) in item" :key="fieldIndex">
            {{ value }}
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Pagination controls -->
    <div class="mt-4 space-x-4">
      <button @click="currentPage = 1" :disabled="currentPage === 1">
        Anfang
      </button>
      <button
        @click="currentPage = currentPage - 1"
        :disabled="currentPage === 1"
      >
        Zurück
      </button>
      <span>Seite {{ currentPage }} von {{ pageCount }}</span>
      <button
        @click="currentPage = currentPage + 1"
        :disabled="currentPage === pageCount"
      >
        Nächste Seite
      </button>
      <button
        @click="currentPage = pageCount"
        :disabled="currentPage === pageCount"
      >
        Ende
      </button>
    </div>
    <p class="text-red-400" v-if="message">{{ message }}</p>
  </div>

  <div v-show="showDetails">
    <div class="mt-10">
      <form
        class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
        @submit.prevent="submitForm"
      >
        <table class="table-auto border-spacing-x-0">
          <tr>
            <td class="px-0 py-2">Feldname</td>
            <td class="px-2 py-2">Inhalt</td>
          </tr>

          <tr
            class="odd:bg-white even:bg-slate-200"
            v-for="(field, index) in formFields"
            :key="index"
          >
            <td>
              <label>{{ field.field }}</label>
            </td>
            <td class="px-2 py-0">
              <input
                v-model="formData[field.field]"
                :ID="field.field"
                :type="field.newType"
                :maxlength="field.newLength"
                :step="field.newType === 'time' ? 1 : null"
                :style="{
                  textTransform:
                    field.newType === 'email' ? 'lowercase' : 'none',
                }"
                :readonly="field.field === 'ID'"
              />
            </td>
          </tr>
        </table>
        <button class="btn btn-blue" type="submit">
          Weiter ({{ this.action }})
        </button>
        <p class="text-red-400" v-if="error">{{ error }}</p>
      </form>
    </div>
  </div>
</template>

<script>
import { fetchData } from "@/services/fetchData";
export default {
  props: {
    msg: String,
  },
  data() {
    return {
      formFields: [],
      formData: {},
      error: "",
      message: "",
      action: "",
      pageSize: 10,
      sortField: "",
      sortOrder: "asc",
      showModal: false,
      showSelect: false,
      modalContent: "",
      showConfirmation: false,
      showDetails: false,
      selectedAction: [], // Initialize selectedAction as an empty array
      newRow: {}, // Object to hold data for new row
      tablesAction: "tables",
      email: "",
      showInfoModal: false,
      loading: false,
      selectedItem: null, // To hold the selected item for more details
      selectedTablesArgument: "",
      items: [],
      data: [], // Initialize an empty array to store fetched data
      fieldNames: [], // Initialize an empty array to store field names
      describe: [], // Initialize an empty array to store field description
      allCounter: "", // total of items
      searchQuery: "", // User's search query
      itemsPerPage: 15, // Default items per page
      currentPage: 1, // Current page
      sortColumn: null,
      sortDirection: 1, // 1 for ascending, -1 for descending

      selectedSearchArgument: "all",

      selectedTables: [], // Track selected tables filters

      uniqueTablesValues: [], // Array to hold unique 'tables' values
    };
  },

  components: {},

  computed: {
    pageCount() {
      return Math.ceil(this.filteredData.length / this.pageSize);
    },
    filteredData() {
      // Apply search filter
      return this.data.filter((item) => {
        return Object.values(item).some((value) => {
          // Handle null values
          if (value === null) {
            return false;
          }
          return value
            .toString()
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase());
        });
      });
    },
    displayedData() {
      // Apply pagination
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.filteredData.slice(start, end);
    },
    filteredTables() {
      const tablesFilters = this.selectedTables;
      this.getTable(tablesFilters);

      let items;

      return items;
    },
    filteredItems() {
      let filteredItems = [];
      // const query = this.searchQuery.toLowerCase();
      const tablesFilters = this.selectedTables;
      // const argument = this.selectedSearchArgument.toLowerCase();

      this.items.forEach((item) => {
        // Check if the item's Status matches any of the selected Status filters
        alert("212");
        // Check if the item's tables matches any of the selected tables filters
        if (this.tablesAction === "tables") {
          const matchesTables =
            tablesFilters.length === 0 || tablesFilters.includes(item.tables);
          if (matchesTables) {
            filteredItems.push(item);
          }
        }
      });

      return filteredItems; // Return the filtered items array
    },

    totalPages() {
      return Math.ceil(this.filteredItems.length / this.itemsPerPage);
    },

    paginatedItemsX() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.sortedItems.slice(startIndex, endIndex);
    },
    paginatedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      let endIndex = startIndex + this.itemsPerPage;

      // Check if endIndex exceeds the length of sortedItems
      if (endIndex > this.sortedItems.length) {
        endIndex = this.sortedItems.length; // Adjust endIndex to prevent exceeding the array length
      }

      // Return the subset of sortedItems based on the startIndex and endIndex
      return this.sortedItems.slice(startIndex, endIndex);
    },

    sortedItems() {
      if (this.sortColumn) {
        // Clone the filteredItems array and sort it based on the selected column and direction
        return [...this.filteredItems].sort((a, b) => {
          if (a[this.sortColumn] < b[this.sortColumn]) {
            return -this.sortDirection;
          } else if (a[this.sortColumn] > b[this.sortColumn]) {
            return this.sortDirection;
          } else {
            return 0;
          }
        });
      } else {
        return this.filteredItems;
      }
    },
  },

  mounted() {
    this.loadContainer();
    // this.selectedAction = new Array(this.displayedData.length).fill("modify");
  },

  watch: {
    formData: {
      handler() {
        if (this.showDetails) {
          this.checkFormDataLength();
        }
      },
      deep: true, // Watch for nested changes in formData
    },
  },

  methods: {
    // Assuming formFields and formData are reactive data properties in your Vue component

    checkFormDataLength() {
      this.error = "";
      this.formFields.forEach((field) => {
        // Check if formData has a value for the current field

        if (field.field in this.formData && field.newLength !== null) {
          const value = String(this.formData[field.field]); // Convert value to a string
          const maxLength = field.newLength;
          // console.log("350", `${field.field}`);
          // Compare the length of the value with the maxLength
          if (value.length > maxLength) {
            console.log(
              `Value for field ${field.field} exceeds maximum length.`
            );
            // You can handle the validation here (e.g., show an error message)
            this.error = ` ${field.field} länger als  ${maxLength}`;
            return;
          }
        }
      });
    },

    validateTimeInput(fieldName) {
      console.log("this.describe", this.describe);
      const fieldValue = this.formData[fieldName];
      console.log("fieldValue", fieldValue);

      // You can add your custom time validation logic here
      // For example, you can check if the input matches a specific time format
      if (fieldName == "time") {
        debugger;
        alert("320");
        const timeRegex = /^(?:[01]\d|2[0-3]):(?:[0-5]\d):(?:[0-5]\d)$/; // hh:mm:ss

        if (!timeRegex.test(fieldValue)) {
          // If the input doesn't match the desired format, you can display an error message or handle it accordingly
          // For demonstration, let's log an error message to the console
          console.error("Invalid time format");
        }
      }
      alert("327");
    },
    filterItems() {
      // Reset pagination to first page when filtering
      this.currentPage = 1;
    },

    toggleSort(fieldName) {
      // Implement sorting logic
      if (this.sortField === fieldName) {
        this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
      } else {
        this.sortField = fieldName;
        this.sortOrder = "asc";
      }

      // Perform sorting of data here
      this.data.sort((a, b) => {
        const valueA = a[fieldName];
        const valueB = b[fieldName];
        const comparison = valueA.localeCompare(valueB, "en", {
          numeric: true,
        });
        return this.sortOrder === "asc" ? comparison : -comparison;
      });
    },

    changePageSize() {
      // Reset pagination to first page when changing page size
      this.currentPage = 1;
    },

    openModal(content) {
      this.showModal = true;
      this.modalContent = content;
      this.showConfirmation = true;
    },
    closeModal() {
      this.showModal = false;
      this.showConfirmation = false;
    },
    confirmAction() {
      this.updateEdit();
      if (this.error) {
        this.closeModal();
        this.showSelect = false;
      } else {
        this.closeModal();
        this.showSelect = true;
        this.showDetails = false;
      }
    },
    cancelAction() {
      this.closeModal();
      this.showSelect = true;
      this.message = "";
      this.showDetails = false;
    },

    async loadContainer() {
      const requestBody = {
        email: this.$store.getters.getMail,
        token: this.$store.getters.getToken,
        action: "tables",
        table: "",
      };
      try {
        const url = "handle_db.php";
        const response = await fetchData(url, requestBody);
        if (response.status === 200) {
          // const xxx = response.data;
          this.loading = false;
          // Map over xxx to extract the 'table' values
          // const uniqueTablesValues = xxx.map((item) => item.table);
          const uniqueTablesValues = response.data.map((item) => item.table);
          // Set uniqueTablesValues in your Vue component data
          this.uniqueTablesValues = uniqueTablesValues;
        }
      } catch (error) {
        //console.error("Error fetching data:", error);
      }
    },

    async getTable(table) {
      this.loading = true;
      const requestBody = {
        email: this.$store.getters.getMail,
        token: this.$store.getters.getToken,
        action: "data",
        table: table,
      };
      this.message = "";
      this.error = "";
      this.showDetails = false;
      this.showSelect = true;

      try {
        const url = "handle_db.php";
        const response = await fetchData(url, requestBody);
        if (response.status === 200) {
          this.data = response.data;

          // Extract field names from the first row of data
          if (this.data.length > 0) {
            this.fieldNames = Object.keys(this.data[0]);
          }

          this.loading = false;
          this.getDescribe(table);
        }
      } catch (error) {
        //console.error("Error fetching data:", error);
      }
    },
    async getDescribe(table) {
      const requestBody = {
        email: this.$store.getters.getMail,
        token: this.$store.getters.getToken,
        action: "describe",
        table: table,
      };

      try {
        const url = "handle_db.php";
        const response = await fetchData(url, requestBody);
        if (response.status === 200) {
          this.describe = response.data;
          this.processDescribeItems(this.describe);
        }
      } catch (error) {
        //console.error("Error fetching data:", error);
      }
    },

    processDescribeItems(describeItems) {
      // Iterate through describe items and preprocess field types
      this.formFields = describeItems.map((field) => {
        const newLength = this.getFieldMaxLength(field.Type);
        let newType = this.getFieldType(field.Type);
        if (
          this.getFieldName(field.field) == "mail" ||
          this.getFieldName(field.field) == "Mail"
        ) {
          newType = "email";
        }
        return {
          field: field.field,
          newType: newType,
          newLength: newLength,
        };
      });
    },

    performAction(action, rowData) {
      this.action = action;
      switch (action) {
        case "modify":
          // Handle modify action
          this.modifyRow(rowData);
          break;
        case "delete":
          // Handle delete action
          this.deleteRow(rowData);
          break;
        case "newcopy":
          // Handle copy action
          rowData.ID = null;
          this.copyRow(rowData);
          break;
        case "new":
          // Handle new action
          rowData.ID = null;
          this.addRow();
          break;
        default:
          break;
      }
    },
    modifyRow(rowData) {
      this.fetchSchema(rowData);
      this.showDetails = true;
      this.showSelect = false;
    },
    deleteRow(rowData) {
      this.fetchSchema(rowData);
      this.showDetails = true;
      this.showSelect = false;
    },
    copyRow(rowData) {
      this.fetchSchema(rowData);
      this.showDetails = true;
      this.showSelect = false;
    },
    addRow() {
      this.fetchSchema(this.newRow);
      this.showDetails = true;
      this.showSelect = false;
    },

    search() {
      this.currentPage = 1; // Reset to the first page when changing the search query
    },
    changeItemsPerPage() {
      this.currentPage = 1; // Reset to the first page when changing items per page
    },
    goToPage(page) {
      this.currentPage = page;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },

    toggleSortX(column) {
      if (column === this.sortColumn) {
        // Toggle sorting direction
        this.sortDirection = -this.sortDirection;
      } else {
        this.sortColumn = column;
        this.sortDirection = 1; // Default to ascending
      }
    },
    getSortIcon(column) {
      if (column === this.sortColumn) {
        return this.sortDirection === 1 ? "▲" : "▼";
      }
      return "";
    },

    getCount(key, value) {
      // Count the occurrences of the specified value for the given key

      if (value.includes("ALL")) {
        return this.allCounter;
      }
      return this.items.filter((item) => item[key] === value).length;
    },

    fetchSchema(rowData) {
      this.formData = Object.assign({}, rowData); // Creates a shallow copy of rowData

      // clear selection
      const index = this.data.findIndex((item) => item.ID === this.formData.ID);
      // If index is found (not -1), clear the selection
      if (index !== -1) {
        // Calculate the index relative to the current page
        const linesPerPage = this.pageSize;
        const lineNumberOnPage = index % linesPerPage;

        // Assuming this.selectedAction is an array maintaining selection state per line
        if (this.selectedAction) {
          // Modify the selectedAction array accordingly
          this.selectedAction[lineNumberOnPage] = null; // or assign any default value
        }
      }

      this.showDetails = true;

      return;
    },
    submitForm() {
      // Handle form submission
      this.error = "";

      this.checkFormDataLength();
      // alert("713 nach checkForm " + this.error);

      // Perform form submission, e.g., send data to server
      if (!this.error) {
        this.openModal(
          "Die Änderung kann nicht rückgängig gemacht werden! Weiter oder abbrechen?"
        );
      }
    },

    async updateEdit() {
      const tablesFilters = this.selectedTables;
      const url = "update_any_DB.php";
      const requestBody = {
        email: this.$store.getters.getMail,
        token: this.$store.getters.getToken,
        table: tablesFilters,
        updates: JSON.stringify(this.formData),
        action: this.action,
      };
      this.message = "";
      console.log(requestBody);
      try {
        const result = await fetchData(url, requestBody);
        if (result.data && result.data.message !== undefined) {
          if (result.status === 200) {
            // If the action was successful
            if (this.action === "delete") {
              // Delete the selected row from the data array
              const index = this.data.findIndex(
                (item) => item.ID === this.formData.ID
              );
              if (index !== -1) {
                this.data.splice(index, 1);
              }
            } else if (this.action === "modify") {
              // Update the selected row in the data array
              const index = this.data.findIndex(
                (item) => item.ID === this.formData.ID
              );
              if (index !== -1) {
                this.data[index] = Object.assign({}, this.formData);
              }
            } else if (this.action === "new" || this.action === "newcopy") {
              console.log("in new, newcopy");
            }
            this.showDetails = false; // Hide the details form
            this.message = "erfolgreich geändert";
          } else if (result.status >= 500 && result.status < 600) {
            //console.log("Server error:", result.status);
            this.error = "Error 5..";
          } else {
            //console.error("Unexpected response status:", result.status);
          }
        } else {
          this.message = result.data.error;
        }
      } catch (error) {
        this.error = "Fatal error";
        //console.error("An error occurred:", error);
      }
    },

    getFieldType(sqlType) {
      // Convert SQL data types to HTML input types
      if (sqlType.includes("int")) {
        return "number";
      } else if (
        sqlType.includes("float") ||
        sqlType.includes("double") ||
        sqlType.includes("decimal")
      ) {
        return "number"; // For floating point numbers
      } else if (sqlType.includes("date")) {
        return "date";
      } else if (sqlType.includes("time")) {
        return "time";
      } else if (
        sqlType.includes("varchar") ||
        sqlType.includes("char") ||
        sqlType.includes("text")
      ) {
        return "text";
      } else {
        // Default to text input for unsupported types
        return "text";
      }
    },
    getFieldName(sqlType) {
      // Convert SQL data types to HTML input types
      if (sqlType.includes("mail") || sqlType.includes("Mail")) {
        return "mail";
      }
    },
    getFieldMaxLength(sqlType) {
      // Extract maximum length for varchar fields
      const match = sqlType.match(/\((.*?)\)/);
      if (match && match[1]) {
        return parseInt(match[1]);
      } else {
        return null;
      }
    },
    getFieldMinValue(sqlType) {
      // Extract minimum value for int fields
      if (sqlType.includes("int")) {
        return Number.MIN_SAFE_INTEGER;
      } else {
        return null;
      }
    },
    getFieldMaxValue(sqlType) {
      // Extract maximum value for int fields
      if (sqlType.includes("int")) {
        return Number.MAX_SAFE_INTEGER;
      } else {
        return null;
      }
    },
  },
};
</script>
<style scoped>
.list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.list-header {
  display: flex;
  background-color: #f0f0f0;
  padding: 5px;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
}

.list-item {
  display: flex;
  padding: 5px;
  border-bottom: 1px solid #ccc;
}

.list-header span,
.list-item span {
  flex: 1;
  text-align: left;
}

.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
}

/* Style the modal content */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 40%;
}

/* Style the close button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.small-table {
  table-layout: auto;
  /* Additional styles for the table */
}
.custom-table {
  table-layout: auto;
  width: 80%; /* Set the table width as needed */
}
.filter-pane {
  display: flex; /* Use flexbox layout */
}

.filter-container {
  margin-bottom: 1rem; /* Add some space between filter containers */
}

.btn-blue {
  @apply bg-blue-500 text-white;
}
</style>
