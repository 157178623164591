<template>
  <div v-show="showModal">
    <UserModal
      :showModal="showModal"
      @close="closeModal"
      @action="handleAction"
      modalTitle="Willst du diese ID wirklich löschen?"
      :showActionBtn="true"
      actionBtnText="Ja"
      cancelBtnText="Nein"
    />
  </div>

  <div>
    <table
      rules="rows"
      cellspacing="5"
      cellpadding="5"
      class="custom-table overflow-x-auto"
    >
      <thead>
        <tr class="odd:bg-white even:bg-slate-50">
          <!-- Column header for the delete button -->
          <th>Delete</th>
          <!-- Dynamically generate column headers -->
          <th
            v-for="(fieldName, index) in fieldNames"
            :key="index"
            align="left"
          >
            <span @click="toggleSort(fieldName)">
              {{ fieldName }} {{ getSortIcon(fieldName) }}
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <!-- Dynamically generate table rows -->
        <!-- class="highlighted-row': selectedRowIndex === rowIndex" -->
        <tr
          v-for="(item, rowIndex) in data"
          :key="rowIndex"
          :class="{
            'bg-yellow-200': selectedRowIndex === rowIndex,
            'bg-gray-100': rowIndex % 2 === 0 && selectedRowIndex !== rowIndex,
            'bg-gray-300': rowIndex % 2 !== 0 && selectedRowIndex !== rowIndex,
          }"
          @mouseover="selectedRowIndex = rowIndex"
        >
          <!-- @mouseleave="selectedRowIndex = -1" -->
          <!-- Column with delete button -->
          <td>
            <span class="close" @click="selectRow(rowIndex)">
              <i class="fa fa-times-circle"></i>
            </span>
          </td>
          <!-- Dynamically generate table cells -->
          <td v-for="(value, fieldIndex) in item" :key="fieldIndex">
            {{ value }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { fetchData } from "@/services/fetchData";
import UserModal from "@/components/UserModal.vue";

export default {
  props: ["isVisible"],
  components: {
    UserModal,
  },

  data() {
    return {
      data: [], // Initialize an empty array to store fetched data
      fieldNames: [], // Initialize an empty array to store field names

      showModal: false,
    };
  },
  created() {
    // Fetch data from the server when the component is created
    this.fetchDataFromServer();
  },
  methods: {
    handleAction(action) {
      // Implement your logic based on the action
      if (action === "Ja" && this.selectedRowIndex !== -1) {
        // Proceed with deletion
        this.deleteID(this.data[this.selectedRowIndex].ID);
        this.data.splice(this.selectedRowIndex, 1);
        this.selectedRowIndex = -1; // Reset or handle as needed
      }
      this.showModal = false; // Close modal
    },

    closeModal() {
      this.showModal = false;
      this.selectedRowIndex = -1; // Reset or handle as needed
    },

    fetchDataFromServer() {
      const tablesFilters = "users";

      this.getTable(tablesFilters);
      let items;

      // console.log(tablesFilters);
      return items;
    },
    async getTable(table) {
      const requestBody = {
        email: this.$store.getters.getMail,
        token: this.$store.getters.getToken,
        action: "data",
        table: table,
      };

      try {
        const url = "handle_db.php";
        const response = await fetchData(url, requestBody);
        if (response.status === 200) {
          this.data = response.data;
          // Extract field names from the first row of data
          if (this.data.length > 0) {
            this.fieldNames = Object.keys(this.data[0]);
          }
          this.loading = false;
        }
      } catch (error) {
        //console.error("Error fetching data:", error);
      }
    },
    async deleteID(id) {
      const url = "DB_updates.php";
      const requestBody = {
        email: this.$store.getters.getMail,
        token: this.$store.getters.getToken,
        ID: id,
        action: "delete",
      };

      try {
        const result = await fetchData(url, requestBody);
        const userData = {
          email: null,
          token: null,
          ipdec: null,
        };
        if (result.status === 200) {
          userData.email = result.data.email;
        } else if (result.status >= 400 && result.status < 500) {
          if (result.data.error) {
            if (result.data.error.message === "IPW") {
              this.error = "Passwort ungültig";
            } else {
              this.error = "ungültige Eingabe";
            }
          }
        } else if (result.status >= 500 && result.status < 600) {
          //console.log("Server error:", result.status);
        } else {
          //console.error("Unexpected response status:", result.status);
        }
      } catch (error) {
        //console.error("An error occurred:", error);
      }
    },
    toggleSort() {
      // Implement your sorting logic here
    },
    getSortIcon() {
      // Implement your sorting icon logic here
    },

    selectRow(rowIndex) {
      // Update selectedRowIndex when a row is selected

      this.selectedRowIndex = rowIndex;
      this.showModal = true;
      this.deleteSelectedRow();
    },
    deleteSelectedRow() {
      let x = this.data[this.selectedRowIndex].ID;
      if (this.selectedRowIndex !== -1) {
        this.modalTitle = "Soll die ID:" + x + " gelöscht werden?";
        this.showModal = true;
      }
    },
  },
};
</script>

<style scoped>
/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 400px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header h2 {
  margin: 0;
}

.modal-header button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
}

.modal-body {
  padding: 20px 0;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
}

.modal-footer button {
  margin-left: 10px;
}
.bg-selected {
  background-color: #f0f0f0; /* Customize the background color for selected rows */
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 500px;
  width: 100%;
}
</style>

