<!-- HomeView -->
<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <div v-if="loading" class="spinner">
      <!-- Your spinner component or loading animation here -->
      <!-- For example, using a simple spinner provided by FontAwesome -->
      <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
    </div>
    <div v-for="item in data" :key="item.ID">
      <div v-html="item.text"></div>
      <!-- <div v-html="item.text" :class="$style.h3"></div> -->
      <!-- You can use v-html to render HTML content -->
    </div>
  </div>
</template>

<script>
import { fetchData } from "@/services/fetchData";
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      data: [],
      loading: false,
    };
  },
  mounted() {
    this.getHomeData();
  },
  methods: {
    async getHomeData() {
      const url = "get_info_neu.php";
      this.loading = true;

      try {
        const result = await fetchData(url);

        if (result.status === 200) {
          this.data = result.data;
          this.loading = false;
        }
        if (result.status >= 400 && result.status < 500) {
          console.log(result.status);
        } else if (result.status >= 500 && result.status < 600) {
          console.log(result.status);
        } else {
          //console.error("Unexpected response status:", result.status);
        }
      } catch (error) {
        //console.error("An error occurred:", error);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!-- <style module> -->
<style>
h1 {
  margin: 10px 0 0;
  font-size: 30px;
}
h3 {
  margin: 10px 0 0;
  color: #b51515;
  font-size: 20px;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
