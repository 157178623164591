<template>
  <div v-if="continuex == false">
    <h1>{{ msg }}</h1>
    <div v-if="loading" class="spinner">
      <!-- Your spinner component or loading animation here -->
      <!-- For example, using a simple spinner provided by FontAwesome -->
      <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
    </div>
    <div class="flex min-h-full flex-col justify-left px-6 py-12 lg:px-8">
      <div class="mt-10 sm:w-full sm:max-w-sm">
        <p class="block text-sm font-medium leading-6 text-gray-900">
          Vor dem Upload müssen die Daten aus Clubdesk 'exportiert' werden.
        </p>
        <p class="block text-sm font-medium leading-6 text-gray-900">
          <b>Achtung:</b> beim Export bitte <br /><b>Spalten: Alle Spalten</b>
          <br />und die Variante<br />
          <b>Format: CSV (Excel)</b><br />
          wählen, damit die Sonderzeichen richtig exportiert werden
        </p>
        <div class="mt-2">
          <input
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            type="file"
            ref="fileInput"
            @change="handleFileChange"
          />
        </div>
        <div>
          <button
            class="mt-2 flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            @click="uploadFile"
          >
            Upload File
          </button>
        </div>
        <p class="text-red-400" v-if="error">{{ error }}</p>
      </div>
    </div>
  </div>
  <div v-if="continuex == true">
    <InsertDB :fileNameCSV="fileNameCSV" :msg="msg" />
  </div>
  <p v-if="error">{{ error }}</p>
</template>

<script>
import InsertDB from "@/components/InsertDB.vue";
export default {
  props: {
    msg: String,
  },
  data() {
    return {
      selectedFile: null,
      fileNameCSV: "",
      continuex: false,
      error: false,
      loading: false,
    };
  },
  components: {
    InsertDB,
  },
  methods: {
    handleFileChange(event) {
      this.error = false;
      this.selectedFile = event.target.files[0];
      // //console.log(event.target.files[0].name)
      this.fileNameCSV = event.target.files[0].name;
      //console.log(this.fileNameCSV);
    },

    uploadFile() {
      if (this.selectedFile) {
        // Define base URL based on environment variable
        let baseURL;

        if (process.env.NODE_ENV === "production") {
          // Production server URL
          baseURL = process.env.VUE_APP_PROD_API_URL;
        } else if (process.env.NODE_ENV === "development") {
          // Development server URL
          baseURL = process.env.VUE_APP_DEV_API_URL;
        } else {
          // Normal server URL
          baseURL = "https://tce.fhco.ch/p/";
        }
        const url = "LoadMitglieder.php";
        const fullUrl = `${baseURL}${url}`;

        this.loading = true;
        const formData = new FormData();
        formData.append("csvFile", this.selectedFile);
        fetch(fullUrl, {
          method: "POST",
          body: formData,
        })
          .then((response) => {
            if (response.ok) {
              this.continuex = true;

              return response.json();
            }
            throw new Error("File upload failed.");
          })
          .then(() => {
            this.loading = false;
            //console.log("File uploaded successfully:", data);
            // Handle the response data as needed
          })
          .catch((error) => {
            ////console.error("Error uploading file:", error.message);
            this.error =
              "Es ist ein Fehler (" +
              error.message +
              ") beim Laden des " +
              this.fileNameCSV +
              " aufgetreten. Bitte Systemadminstrator informieren.";
            // Handle errors here
          });
      } else {
        ////console.error("No file selected.");
        // Handle no file selected error
      }
    },
  },
};
</script>
