<template>
  <!-- Modal to display information -->
  <div v-if="showInfoModal" class="modal-overlay" @click="closeModalOutside">
    <div class="modal" @click.stop>
      <div class="modal-header">
        <span class="close" @click="closeInfoModal">
          <i class="fa fa-times-circle"></i>
          <!-- Font Awesome back arrow icon -->
        </span>
      </div>
      <div class="modal-content">
        <!-- Display more details based on the clicked item -->
        <!-- Display more details based on the selected item -->
        <table v-if="selectedItem" class="small-table">
          <tr v-for="(value, key) in selectedItem" :key="key">
            <td>{{ key }}</td>
            <td>{{ value }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>

  <div class="mt-10">
    <h1 class="text-3xl">{{ msg }}</h1>
    <div v-if="loading" class="spinner">
      <!-- Your spinner component or loading animation here -->
      <!-- For example, using a simple spinner provided by FontAwesome -->
      <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
    </div>
    <input v-model="searchQuery" placeholder="Suchen..." @input="filterItems" />
    <label class="text-1xl" for="selectedSearchArgument"
      >Zusätzliche Auswahl (fak.):
    </label>
    <select v-model="selectedSearchArgument" @change="filterItems">
      <option value="all" selected>Alle</option>
      <option value="newl">Eintritt Vorjahr</option>
      <option value="newc">Eintritt Laufendes Jahr</option>
      <option value="old">Old</option>
    </select>
    <table
      rules="rows"
      cellspacing="5"
      cellpadding="5"
      class="custom-table overflow-x-auto"
    >
      <thead>
        <tr class="odd:bg-white even:bg-slate-50">
          <th style="width: 10%" align="left">
            <span @click="toggleSort('Anrede')"
              >Anrede {{ getSortIcon("Anrede") }}
            </span>
          </th>
          <th style="width: 10%" align="left">
            <span @click="toggleSort('Vorname')"
              >Vorname {{ getSortIcon("Vorname") }}
            </span>
          </th>
          <th style="width: 10%" align="left">
            <span @click="toggleSort('Nachname')"
              >Nachname{{ getSortIcon("Nachname") }}
            </span>
          </th>
          <th style="width: 10%" align="left">
            <span @click="toggleSort('Status')"
              >Status{{ getSortIcon("Status") }}
            </span>
          </th>
          <th style="width: 10%" align="left">
            <span @click="toggleSort('PLZ')">PLZ{{ getSortIcon("PLZ") }} </span>
          </th>
          <th style="width: 10%" align="left">
            <span @click="toggleSort('Ort')">Ort{{ getSortIcon("Ort") }} </span>
          </th>
          <th style="width: 10%" align="left"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="odd:bg-white even:bg-slate-200"
          v-for="(item, index) in paginatedItems"
          :key="index"
        >
          <td>
            {{ item.Anrede }}
          </td>
          <td>
            {{ item.Vorname }}
          </td>
          <td>
            {{ item.Nachname }}
          </td>
          <td>
            {{ item.Status }}
          </td>
          <td>
            {{ item.PLZ }}
          </td>
          <td>
            {{ item.Ort }}
          </td>
          <td>
            <span @click="handleClick(item.ID)">
              <i class="fa fa-info-circle"></i>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="pagination">
      <button class="mr-3" @click="goToPage(1)" :disabled="currentPage === 1">
        Anfang
      </button>
      <button class="mr-3" @click="previousPage" :disabled="currentPage === 1">
        Zurück
      </button>
      <span class="mr-3 current-page">{{ currentPage }}</span>
      <button
        class="mr-3"
        @click="nextPage"
        :disabled="currentPage === totalPages"
      >
        Nächste
      </button>
      <button
        class="mr-3"
        @click="goToPage(totalPages)"
        :disabled="currentPage === totalPages"
      >
        Ende
      </button>
    </div>
    <div class="pagination-options">
      <label for="itemsPerPage">Zeilen pro Seite:</label>
      <select v-model="itemsPerPage" @change="changeItemsPerPage">
        <option value="15">15</option>
        <option value="30">30</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
    </div>
    <hr class="h-px my-4 bg-gray-700 border-0 dark:bg-gray-700" />

    <div class="filter-pane space-x-16">
      <!-- Anrede filter -->
      <div class="filter-container">
        <!-- <label for="anredeFilter">Anrede:</label> -->
        <p class="text-2xl">Anrede</p>
        <select
          id="anredeFilter"
          v-model="selectedAnrede"
          multiple
          size="5"
          @change="filterItems"
        >
          <option
            v-for="(value, index) in uniqueAnredeValues"
            :key="index"
            :value="value"
          >
            {{ value }} ({{ getCount("Anrede", value) }})
          </option>
        </select>
      </div>

      <!-- Status filter -->
      <div class="filter-container">
        <!-- <label for="statusFilter">Status:</label> -->
        <p class="text-2xl">Status</p>
        <select
          id="statusFilter"
          v-model="selectedStatus"
          multiple
          size="5"
          @change="filterItems"
        >
          <option
            v-for="(value, index) in uniqueStatusValues"
            :key="index"
            :value="value"
          >
            {{ value }} ({{ getCount("Status", value) }})
          </option>
        </select>
      </div>

      <!-- Ort filter -->
      <div class="filter-container">
        <!-- <label for="ortFilter">Ort:</label> -->
        <p class="text-2xl">Ort</p>
        <select
          id="ortFilter"
          v-model="selectedOrt"
          multiple
          size="5"
          @change="ilteredItems"
        >
          <option
            v-for="(value, index) in uniqueOrtValues"
            :key="index"
            :value="value"
          >
            {{ value }} ({{ getCount("Ort", value) }})
          </option>
        </select>
      </div>
    </div>
  </div>
  <p class="text-red-400" v-if="error">{{ error }}</p>
</template>

<script>
import { fetchData } from "@/services/fetchData";
export default {
  props: {
    msg: String,
  },
  name: "YourComponentxxxxxxxxxxxx",
  data() {
    return {
      error: "",
      email: "",
      showInfoModal: false,
      loading: false,
      selectedItem: null, // To hold the selected item for more details
      items: [],
      allCounter: "", // total of items
      searchQuery: "", // User's search query
      itemsPerPage: 15, // Default items per page
      currentPage: 1, // Current page
      sortColumn: null,
      sortDirection: 1, // 1 for ascending, -1 for descending
      eintrittLastYear: null,
      eintrittCurrentYear: null,
      eintrittDate: null, // Replace this with your new selection date
      austrittDate: null, // Replace this with your new selection date
      selectedSearchArgument: "all",
      showAdwords: false, // Adwords column is hidden
      selectedAnrede: [], // Track selected name filters
      selectedStatus: [], // Track selected status filters
      selectedOrt: [], // Track selected status filters
      uniqueAnredeValues: [], // Array to hold unique 'name' values
      uniqueStatusValues: [], // Array to hold unique 'status' values
      uniqueOrtValues: [], // Array to hold unique 'status' values
    };
  },

  computed: {
    filteredItems() {
      let filteredItems = [];
      const query = this.searchQuery.toLowerCase();
      const anredeFilters = this.selectedAnrede;
      const statusFilters = this.selectedStatus;
      const ortFilters = this.selectedOrt;
      const argument = this.selectedSearchArgument.toLowerCase();
      const minAustritt = this.austrittDate;

      this.items.forEach((item) => {
        const entryDate = new Date(item.Eintritt);
        const leavingDate = new Date(item.Austritt);

        if (
          (argument === "all" ||
            (argument === "newl" &&
              entryDate >= this.eintrittLastYear &&
              entryDate < this.eintrittCurrentYear) ||
            (argument === "newc" && entryDate >= this.eintrittCurrentYear) ||
            (argument === "old" && leavingDate >= minAustritt)) &&
          (item.Anrede.toLowerCase().includes(query) ||
            item.Nachname.toLowerCase().includes(query) ||
            item.Vorname.toLowerCase().includes(query) ||
            item.Status.toLowerCase().includes(query) ||
            item.PLZ.toLowerCase().includes(query) ||
            item.Ort.toLowerCase().includes(query))
        ) {
          // Check if the item's Anrede matches any of the selected Anrede filters
          const matchesAnrede =
            anredeFilters.length === 0 ||
            anredeFilters.includes("ALL") ||
            anredeFilters.includes(item.Anrede);

          // Check if the item's Status matches any of the selected Status filters
          const matchesStatus =
            statusFilters.length === 0 ||
            statusFilters.includes("ALL") ||
            statusFilters.includes(item.Status);

          // Check if the item's Ort matches any of the selected Ort filters
          const matchesOrt =
            ortFilters.length === 0 ||
            ortFilters.includes("ALL") ||
            ortFilters.includes(item.Ort);
          if (matchesAnrede && matchesStatus && matchesOrt) {
            filteredItems.push(item);
          }
        }
      });

      return filteredItems; // Return the filtered items array
    },

    totalPages() {
      return Math.ceil(this.filteredItems.length / this.itemsPerPage);
    },

    paginatedItemsX() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.sortedItems.slice(startIndex, endIndex);
    },
    paginatedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      let endIndex = startIndex + this.itemsPerPage;

      // Check if endIndex exceeds the length of sortedItems
      if (endIndex > this.sortedItems.length) {
        endIndex = this.sortedItems.length; // Adjust endIndex to prevent exceeding the array length
      }

      // Return the subset of sortedItems based on the startIndex and endIndex
      return this.sortedItems.slice(startIndex, endIndex);
    },

    sortedItems() {
      if (this.sortColumn) {
        // Clone the filteredItems array and sort it based on the selected column and direction
        return [...this.filteredItems].sort((a, b) => {
          if (a[this.sortColumn] < b[this.sortColumn]) {
            return -this.sortDirection;
          } else if (a[this.sortColumn] > b[this.sortColumn]) {
            return this.sortDirection;
          } else {
            return 0;
          }
        });
      } else {
        return this.filteredItems;
      }
    },
  },

  mounted() {
    this.getMembers();
    this.computeEintritt();
  },

  metaInfo: {
    title: "TCE",
    meta: [
      {
        name: "robots",
        content: "noindex",
      },
    ],
  },

  methods: {
    search() {
      this.currentPage = 1; // Reset to the first page when changing the search query
    },
    changeItemsPerPage() {
      this.currentPage = 1; // Reset to the first page when changing items per page
    },
    goToPage(page) {
      this.currentPage = page;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },

    toggleSort(column) {
      if (column === this.sortColumn) {
        // Toggle sorting direction
        this.sortDirection = -this.sortDirection;
      } else {
        this.sortColumn = column;
        this.sortDirection = 1; // Default to ascending
      }
    },
    getSortIcon(column) {
      if (column === this.sortColumn) {
        return this.sortDirection === 1 ? "▲" : "▼";
      }
      return "";
    },

    filterItems() {
      // Reset to the first page when changing the filters
      this.currentPage = 1;

      const query = this.searchQuery.toLowerCase().trim();
      const anredeFilters = this.selectedAnrede;
      const statusFilters = this.selectedStatus;
      const ortFilters = this.selectedOrt;

      // Filter items based on the search query and selected filters
      this.filteredItems = this.items.filter((item) => {
        // Check if the search query matches any of the item's fields
        const matchesQuery =
          item.ID.toLowerCase().includes(query) ||
          item.Nachname.toLowerCase().includes(query) ||
          item.Vorname.toLowerCase().includes(query) ||
          item.Ort.toLowerCase().includes(query) ||
          item.Status.toLowerCase().includes(query);
        // Check if the item's Anrede matches any of the selected Anrede filters
        // const matchesAnrede =
        //   anredeFilters.length === 0 || anredeFilters.includes(item.Anrede);

        const matchesAnrede =
          anredeFilters.includes("ALL") || // Check if "ALL" is included
          anredeFilters.length === 0 || // Check if no specific options are selected
          anredeFilters.includes(item.Anrede); // Check if the item's Anrede matches the selected options

        // Check if the item's Status matches any of the selected Status filters
        const matchesStatus =
          statusFilters.includes("ALL") || // Check if "ALL" is included
          statusFilters.length === 0 ||
          statusFilters.includes(item.Status);

        // Check if the item's Ort matches any of the selected Ort filters
        const matchesOrt =
          ortFilters.includes("ALL") || // Check if "ALL" is included
          ortFilters.length === 0 ||
          ortFilters.includes(item.Ort);

        // Return true if all conditions are met (logical AND)
        return matchesQuery && matchesAnrede && matchesStatus && matchesOrt;
      });
    },

    filterTableData() {
      // Initialize counts
      const counts = {
        Anrede: {},
        Status: {},
        Ort: {},
      };
      // Count occurrences of each value

      this.items.forEach((item) => {
        counts.Anrede[item.Anrede] = (counts.Anrede[item.Anrede] || 0) + 1;
        counts.Status[item.Status] = (counts.Status[item.Status] || 0) + 1;
        counts.Ort[item.Ort] = (counts.Ort[item.Ort] || 0) + 1;
        this.allCounter++;
      });

      // Update the unique value arrays and counts

      const anredeKeys = Object.keys(counts.Anrede);
      anredeKeys.sort();
      const statusKeys = Object.keys(counts.Status);
      statusKeys.sort();
      const ortKeys = Object.keys(counts.Ort);
      ortKeys.sort();

      // this.uniqueAnredeValues = [
      //   "ALL (" + this.allCounter + ")",
      //   ...anredeKeys,
      // ];
      this.uniqueAnredeValues = ["ALL", ...anredeKeys];
      this.uniqueStatusValues = ["ALL", ...statusKeys];
      this.uniqueOrtValues = ["ALL", ...ortKeys];

      // Generate options for Anrede filter
      this.uniqueAnredeValues.forEach((value, index) => {
        const optionElement = document.getElementById(
          `anredeFilterOption${index}`
        );
        if (optionElement) {
          let count = 0;
          if (value === "ALL") {
            count = this.items.length;
          } else {
            count = counts.Anrede[value] || 0;
          }
          optionElement.innerHTML = `<option value="${value}">${value} (${count})</option>`;
        }
      });

      // Generate options for Status filter
      this.uniqueStatusValues.forEach((value, index) => {
        const optionElement = document.getElementById(
          `statusFilterOption${index}`
        );
        if (optionElement) {
          let count = 0;
          if (value === "ALL") {
            count = this.items.length;
          } else {
            count = counts.Status[value] || 0;
          }
          optionElement.innerHTML = `<option value="${value}">${value} (${count})</option>`;
        }
      });

      // Generate options for Ort filter
      this.uniqueOrtValues.forEach((value, index) => {
        const optionElement = document.getElementById(
          `ortFilterOption${index}`
        );
        if (optionElement) {
          let count = 0;
          if (value === "ALL") {
            count = this.items.length;
          } else {
            count = counts.Ort[value] || 0;
          }
          optionElement.innerHTML = `<option value="${value}">${value} (${count})</option>`;
        }
      });

      // Filter table data based on selected filters
      const filteredData = this.items.filter((item) => {
        const anredeFilter =
          this.selectedAnrede.length === 0 ||
          this.selectedAnrede.includes(item.Anrede) ||
          this.selectedAnrede.includes("ALL");
        const statusFilter =
          this.selectedStatus.length === 0 ||
          this.selectedStatus.includes(item.Status) ||
          this.selectedStatus.includes("ALL");
        const ortFilter =
          this.selectedOrt.length === 0 ||
          this.selectedOrt.includes(item.Ort) ||
          this.selectedOrt.includes("ALL");
        return anredeFilter && statusFilter && ortFilter;
      });

      // Update displayed items
      this.displayedItems = filteredData;
    },

    getCount(key, value) {
      // Count the occurrences of the specified value for the given key

      if (value.includes("ALL")) {
        return this.allCounter;
      }
      return this.items.filter((item) => item[key] === value).length;
    },

    async getMembersX() {
      this.loading = true;
      try {
        const response = await fetch("get_mitglieder_vue.php"); // Replace with the actual URL of your PHP script
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        this.items = jsonData;
        this.loading = false;
        this.filterTableData();
      } catch (error) {
        //console.error("Error fetching data:", error);
      }
    },

    async getMembers() {
      const requestBody = {
        email: this.$store.getters.getMail,
        token: this.$store.getters.getToken,
      };
      try {
        this.loading = true;
        const url = "get_mitglieder_vue.php";
        const response = await fetchData(url, requestBody);
        // const status =
        //   response.status || (response.data && response.data.status);
        // //console.log(status);

        if (response.status === 200) {
          console.log(response);
          // if (response.status === 200) {
          // this.data = response.data;
          const jsonData = response.data;
          this.items = jsonData;
          this.filterTableData();
          this.loading = false;
        } else if (response.status >= 400 && response.status < 500) {
          console.log(response);
          this.error = "Zugriff verweigert";
          this.loading = false;
        }
      } catch (error) {
        //console.error("Error fetching data:", error);
      }
    },

    async handleClick(item) {
      this.email = this.$store.getters.getMail;
      if (this.email <= "") {
        alert(
          "etwas ist schief gelaufen, bitte Abmelden und nochmals Anmelden"
        );
        return;
      }
      const url = "get_mitglieder_details_vue.php";
      const requestBody = {
        id: item,
        email: this.$store.getters.getMail,
      };
      try {
        const response = await fetchData(url, requestBody);
        // const status =
        //   response.status || (response.data && response.data.status);
        // //console.log(status);

        if (response.ok) {
          throw new Error(`Request failed with status ${response.status}`);
        }
        if (response.status === 200) {
          const additionalDetails = response.data;
          this.selectedItem = { ...additionalDetails };
          // Show the information modal
          this.showInfoModal = true;
        }
      } catch (error) {
        //console.error("An error occurred:", error);
      }
    },

    closeInfoModal() {
      this.showInfoModal = false;
    },
    closeModalOutside(event) {
      if (event.target.classList.contains("modal-overlay")) {
        this.closeInfoModal();
      }
    },

    computeEintritt() {
      // Get the current date
      const currentDate = new Date();

      // Get the month and year from the current date
      const currentMonth = currentDate.getMonth() + 1; // Months are zero-based, so add 1
      const currentYear = currentDate.getFullYear();

      // Get the month and year from the new selection date
      // const [yearStr, monthStr] = this.newSelectionDate.split("-");
      // const selectionMonth = parseInt(monthStr);
      // const selectionYear = parseInt(yearStr);

      // set last year
      const newDate1 = new Date(currentYear - 1, 0, 1); // Month 0 is Jan (zero-based)
      this.eintrittLastYear = newDate1;

      // set current year
      const newDate2 = new Date(currentYear, 0, 1); // January is month 0
      this.eintrittCurrentYear = newDate2;

      // Check if the current date is less than July
      if (currentMonth < 7) {
        // Set to July 1st of the previous year
        const newDate = new Date(currentYear - 1, 6, 1); // Month 6 is July (zero-based)

        this.eintrittDate = newDate;

        return newDate.toDateString();
      } else {
        // Set the new selection date to January 1st of the current year
        const newDate = new Date(currentYear, 0, 1); // January is month 0
        this.eintrittDate = newDate;
        this.austrittDate = newDate;
        // return newDate.toDateString();
      }
      // set Austritt ab September Vorjahr
      const newDate = new Date(currentYear - 1, 8, 1); // september)
      this.austrittDate = newDate;
    },
  },
};
</script>
<style scoped>
.list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.list-header {
  display: flex;
  background-color: #f0f0f0;
  padding: 5px;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
}

.list-item {
  display: flex;
  padding: 5px;
  border-bottom: 1px solid #ccc;
}

.list-header span,
.list-item span {
  flex: 1;
  text-align: left;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  position: relative; /* Make the position relative for proper positioning */
}

.modal-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
}
.close {
  cursor: pointer;
  font-size: 20px; /* Adjust as needed */
  margin-right: 10px; /* Add spacing between the close symbol and content */
}

.small-table {
  table-layout: auto;
  /* Additional styles for the table */
}
.custom-table {
  table-layout: auto;
  width: 80%; /* Set the table width as needed */
}
.filter-pane {
  display: flex; /* Use flexbox layout */
}

.filter-container {
  margin-bottom: 1rem; /* Add some space between filter containers */
}
</style>
