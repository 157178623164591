


// store/modules/session.js
const state = {
    timeoutDuration: 10 * 60 * 1000, // 30 minutes in milliseconds
    countdown: null,
    warning: false,
    exit: true,
  };
  
  const mutations = {
    startTimeout(state) {
      if(state.exit) return;  // TEST TEST
      //console.log("startTimeout")
      alert("in startTimeout")
      alert(state.timeoutDuration)
      state.countdown = setTimeout(() => {
        state.warning = true;
      }, state.timeoutDuration - 3 * 60 * 1000); // 3 minutes before timeout
      alert(state.timeoutDuration)
    },
    resetTimeout(state) {
      if(state.exit) return;  // TEST TEST
      //console.log("resetTimeout")
      clearTimeout(state.countdown);
      state.warning = false;

    },
  };
  
  const actions = {
    initializeTimeout({ commit }) {
      if(state.exit) return;  // TEST TEST
      alert("startTimeout")
      //console.log("startTimeout")
      commit('startTimeout');
    },
    resetTimeout({ commit }) {
      if(state.exit) return;  // TEST TEST
      //console.log("resetTimeout")
      commit('resetTimeout');
    },
  };
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions,
  };
  