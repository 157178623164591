// fetchData.js

// Define base URL based on environment variable
let baseURL;

if (process.env.NODE_ENV === 'production') {
  // Production server URL
    baseURL = process.env.VUE_APP_PROD_API_URL;
} else if (process.env.NODE_ENV === 'development') {
  // Development server URL
  baseURL = process.env.VUE_APP_DEV_API_URL;
} else {
  // Normal server URL
  baseURL = "https://tce.fhco.ch/p/";
}


export async function fetchData(url, requestBody, method = "POST") {
  const options = {
    method: method,
  };

  // Only include the request body for non-GET requests
  if (method !== "GET") {
    options.body = JSON.stringify(requestBody);
  }

  const fullUrl = `${baseURL}${url}`;
 
  try {
    const response = await fetch(fullUrl, options);

    return {
      status: response.status,
      statusText: response.statusText,
      data: await response.json()
    }

  } catch (error) {
    return {
      status: error.status || 500,
      statusText: error.statusText || "Internal Server Error",
      data: null
    }
  }
}