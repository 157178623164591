<template>
  <section class="home">
    <div class="home-text container">
      <!-- <img alt="Vue logo" src="../assets/TCE_Logo.svg" width="100" height="200"> -->
      <HelloWorld msg="Willkommen beim Tennisclub Engematt" />
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "HomeView",
  components: {
    HelloWorld,
  },
};
</script>
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css?family=Raleway:wght:@400,500@display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
}
.home {
  min-height: 100vh;
  position: relative;
  background-color: #f1f1f1;
}
.container {
  padding: 0 20px;
  max-width: 1140px;
  margin: 0 left;
}
</style>

