<template>
  <div v-if="showModal" class="modal-overlay">
    <div class="modal">
      <!-- Modal content -->
      <div class="modal-header">
        <h2>{{ modalTitle }}</h2>
      </div>
      <div class="modal-body">
        <!-- Insert dynamic content based on the use case -->
        <slot></slot>
      </div>
      <div class="modal-footer">
        <button
          class="ok-btn"
          @click="handleAction(actionBtnText)"
          v-if="showActionBtn"
        >
          {{ actionBtnText }}
        </button>
        <span style="margin: 0 10px"></span>
        <!-- <button @click="handleAction('delete')">Delete</button> -->
        <button class="cancel-btn" @click="closeModal">
          {{ cancelBtnText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showModal: Boolean,
    modalTitle: String,
    showActionBtn: Boolean,
    actionBtnText: String,
    cancelBtnText: String,
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    handleAction(action) {
      this.$emit("action", action);
    },
  },
  mounted() {
    console.log("Mounted Modal Title:", this.modalTitle);
    console.log("Mounted Modal actionBtnText:", this.actionBtnText);
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-footer {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
.modal-overlay {
  z-index: 1000; /* Increase if needed */
  /* Other styles */
}

.modal {
  z-index: 1001; /* Ensure this is higher than the overlay */
}
</style>
