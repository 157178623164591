<template>
  <div>
  <h1>{{ msg }}</h1>
    <div class="flex min-h-full flex-col justify-left px-6 py-12 lg:px-8">
      <!-- <div class="mt-10 sm:w-full sm:max-w-sm"> -->
      <div>
        <h2 class="text-4xl font-bold dark:text-white">
          Die Mitgliederliste wurde mit diesen Daten geladen.
        </h2>
        <h3 class="text-3xl font-bold text-yellow-500">{{ fileNameCSV }}</h3>

        <h4 class="mt-10 text-2xl  dark:text-white">
          Bitte kontrolliere, ob der Upload ok ist. Beachte insbesondere die
          Sonderzeichen - wie zum Beispiel Zürich - unter Ort.
        </h4>
        <h4 class="mt-10 text-2xl  dark:text-white">
          Sicherheitshalber gibt es zu jedem File eine Sicherung, falls etwas
          zurückgestellt werden müsste.
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["fileNameCSV", "msg"],
  data() {
    return {};
  },
};
</script>
