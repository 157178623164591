// SignUp.vue
<template>
  <div>
    <h1 class="text-3xl" v-if="!showConfirmation">Konto erstellen (registrieren)</h1>
    <h1 class="text-3xl" v-else>Konto erstellen - Bestätigung</h1>

    <template v-if="!showConfirmation">
      <SignUpComponent @login-success="handleLoginSuccess" />
    </template>

    <template v-else>
      <ConfirmationComponent
        :loginData="loginData"
        @confirmation-success="handleConfirmation"
      />
    </template>
  </div>
</template>

<script>
import SignUpComponent from "../components/SignUpComponent.vue";
import ConfirmationComponent from "../components/ConfirmationComponent.vue";

export default {
  data() {
    return {
      showConfirmation: false,
      loginData: null,
      tan: "",
    };
  },
  methods: {
    handleLoginSuccess(data) {
      alert("in SignUp: handleLoginSuccess");
      this.loginData = data;
      this.loginData = data;
      this.showConfirmation = true;

      //console.log(this.loginData);
    },
    handleConfirmation(data) {
      // Handle confirmation logic, e.g., store TAN
      alert("in signUp: handleConfirmation");
      this.tan = data.tan;
      // You can also perform any other actions here
      // alert("back from confirmation");
      // Set user credentials and navigate to authenticated route
      const userCredential = true;
      this.$store.commit("setUser", userCredential);
      this.$router.push({ name: "HomeView" });
    },
  },
  components: {
    SignUpComponent,
    ConfirmationComponent,
  },
};
</script>

