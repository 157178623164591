<!-- ConfirmationComponent.vue -->
<template>
  <div class="flex min-h-full flex-col justify-left px-6 py-12 lg:px-8">
    <div class="mt-10 sm:w-full sm:max-w-sm">
      <form
        @submit.prevent="confirm"
        class="space-y-6"
        action="#"
        method="POST"
      >
        <!-- You can access the loginData prop here -->
        <p>Bitte TAN bestätigen -> siehe Mail an: {{ loginData.email }}</p>

        <label
          for="tan"
          class="block text-sm font-medium leading-6 text-gray-900"
          >TAN:</label
        >
        <div class="mt-2">
          <input
            id="tan"
            v-model="tan"
            type="text"
            minlength="4"
            maxlength="4"
            required
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
        <div>
          <button
            type="submit"
            class="mt-2 flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Senden
          </button>
        </div>
        <p class="text-red-400" v-if="!ok">Falsche Eingabe</p>
      </form>
    </div>
  </div>
</template>

<script>
import { fetchData } from "@/services/fetchData";

export default {
  props: {
    loginData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tan: "",
      ok: true,
    };
  },
  methods: {
    async confirm() {
      // Perform confirmation logic, e.g., validate TAN
      if (this.tan) {
        this.ok = true;

        // Call the authentication service for confirmation
        const confirmationResult = await this.confirmAuthentication();

        // Emit the event with the TAN and login data if confirmation is successful
        if (confirmationResult.success) {
          this.$emit("confirmation-success", {
            tan: this.tan,
            loginData: this.loginData,
          });
        } else {
          // alert("Confirmation failed. Please try again.");
          this.ok = false;
        }
      } else {
        this.ok = false;
        // alert("Please enter a valid TAN.");
      }
    },

    async confirmAuthentication() {
      const url = "forget_confirm_vue.php";
      const requestBody = {
        email: this.loginData.email,
        tan: this.tan,
      };
      //console.log(this.loginData);

      try {
        const result = await fetchData(url, requestBody);
        //console.log("Confirmation response:", result);

        if (result.status === 200 || result.status === 201) {
          return { success: true, data: result };
        } else if (result.status >= 400 && result.status < 500) {
          //console.log("Client error:", result.status);
          // ... handle other client errors
          return { success: false, status: result.status };
        } else if (result.status >= 500 && result.status < 600) {
          //console.log("Server error:", result.status);
          // ... handle other server errors
          return { success: false, status: result.status };
        } else {
          //console.error("Unexpected response status:", result.status);
          return { success: false, status: result.status };
        }
      } catch (error) {
        //console.error("An error occurred during confirmation:", error);
        return { success: false, error };
      }
    },
  },
};
</script>
